<template>
  <div v-if="related && related.length > 0" class="col-md-12">
      <h4 class="text-center">Weitere Elemente in Ihrem Muster konfigurieren</h4>
      <div class="row">
        <div class="col-md-4" v-for="(item, index) in related" :key="index">
          <h6 class="text-center">{{ item.name }}</h6>
          <div class="card pointer">
            <div class="text-center pt-3">
              <img class="card-img-top" :src="$filters.image(item.image, '190x')">
            </div>
            <div class="card-body text-center px-1">
              <p class="card-title">{{ item.option }}</p>
            </div>
            <a :href="item.slug" class="card-footer bg-primary text-white text-center">Auswählen</a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const related = computed(() => {
      return store.getters['related/relateds']
    })
    onMounted(() => {
      store.dispatch('related/setRelated', store.getters['checkout/items'])
    })
    return {
      related
    }
  }
}
</script>
